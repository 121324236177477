import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'



import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
// Import Bootstrap an BootstrapVue CSS files (order is important)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
//import '@fortawesome/fontawesome-free/css/all.css'
//import '@fortawesome/fontawesome-free/js/all.js'

//Leaflet
import { LMap, LTileLayer, LMarker, LPolygon ,LPolyline, LControl,LControlLayers,LCircle,LCircleMarker,LTooltip,LIcon  ,LGeoJson,LPopup,LLayerGroup,LControlZoom,LControlScale,LWMSTileLayer } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-polyline', LPolyline);
Vue.component('l-control', LControl);
Vue.component('l-control-layers', LControlLayers);
Vue.component('l-polygon', LPolygon);LCircle
Vue.component('l-circle', LCircle);
Vue.component('l-tooltip', LTooltip);
Vue.component('l-geo-json', LGeoJson);
Vue.component('l-popup', LPopup);
Vue.component('l-layer-group', LLayerGroup);
Vue.component('l-control-zoom', LControlZoom);
Vue.component('l-control-scale', LControlScale);
Vue.component('l-wms-tile-layer', LWMSTileLayer);
Vue.component('l-icon', LIcon);
Vue.component('l-circle-marker', LCircleMarker);
delete L.Icon.Default.prototype._getIconUrl;
import L from 'leaflet';
L.Icon.Default.mergeOptions({
   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
   iconUrl: require('leaflet/dist/images/marker-icon.png'),
   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


import { DraggablePlugin, DraggableDirective } from '@braks/revue-draggable';

// Use as Plugin
Vue.use(DraggablePlugin)

// or
Vue.directive('draggable', DraggableDirective)
//Vue.component('Draggable', Draggable)
import Multiselect from 'vue-multiselect'

  // register globally
Vue.component('multi-select', Multiselect)





// Make BootstrapVue available throughout your project

// Optionally install the BootstrapVue icon components plugin
//import VueHtml2Canvas from 'vue-html2canvas';
 
//Vue.use(VueHtml2Canvas);



Vue.config.productionTip = false
Vue.config.devtools = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
