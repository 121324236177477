import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'
import store from '../store/index'
Vue.use(VueRouter)
import axios from '../axios-auth'
const routes = [
  {
    path: '/',
    name: '',
    component: () => import( '../components/signin.vue'),
    //component: () => import( '../components/login_page.vue'),
    
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/Dashboard.vue'),
    redirect: '/dashboard/demo',
    children: [
        {
            path: 'demo',
            name: 'Demo',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( '../components/Demo.vue'),
            beforeEnter (to, from, next) {
              
                //store.dispatch('pageName',{name:'Entomological Data'})
                //next()
                testAuth(next)
             
            }
          },
     
    ]
    
  },
 
]


async function testAuth(next){
  if (store.getters.isAuthenticated) {
    next()
  } else {
  
    var token=localStorage.getItem('token')


    console.log("before_axios")
     axios.post('verifytoken/',{ token: token })
      .then(
       //console.log(response)
        next()
        //console.log(response)
      )
       .catch(err => {
       
        next( '/' )
        //next( )
        //store.dispatch('logout')
        store.dispatch('logout')
       console.log(err)
     })
     

  }
}


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



export default router
